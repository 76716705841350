<template>
    <!-- ======= Cabecero ======= -->
  <header id="header" class="fixed-top">
    <div class="container d-flex align-items-center">     
       <a href="#" class="logo me-auto"><img src="@/assets/img/logo_uniovi.png" alt="Universidad de Oviedo" title="Universidad de Oviedo" class="img-fluid"></a>
      <nav id="navbar" class="navbar order-last order-lg-0">
        <ul>
          <li v-for="link_menu in menu">
            <router-link :to="link_menu.LINK!='inicio' ? '/'+link_menu.LINK : '/'" active-class="active">{{link_menu.NAME}}</router-link>
          </li>
          
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav><!-- fin navbar -->
    </div>
  </header><!-- fin Cabecero -->
</template>

<script>

import getMenu from "../../../helpers/getMenu.ts"

export default {
  data() {
    return {
      menu: []
    }
  },
  methods:{                
    async loadMenu(){
      this.menu = await getMenu()      
    }
  },
  created(){        
      this.loadMenu();
  }
}
</script>